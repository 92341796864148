@font-face {
  font-family: "Satoshi";
  src: url("../assets/font/Satoshi-Variable.ttf") format("truetype");
}

@font-face {
  font-family: "ClashDisplay";
  src: url("../assets/font/ClashDisplay-Variable.ttf") format("truetype");
}

@font-face {
  font-family: "SplineSansMono";
  src: url("../assets/font/SplineSansMono-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "MigraItalic";
  src: url("../assets/font/MigraItalic-ExtralightItalic.otf") format("opentype");
}
