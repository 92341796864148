.footer {
  margin-top: 60px;
  margin-bottom: 20px;
  margin-left: 27px;
  margin-right: 27px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: opacity 0.5s ease-in-out;

  &__texte {
    margin: 0;
  }

  &__el {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #333;
    font-weight: 700;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 1px;
      background-color: #476662;
      transition: width 0.3s ease;
    }
  }

  &__contact {
    margin-bottom: 10px;
  }

  &__centre {
    display: flex;
    flex-direction: column;
  }

  &__credits {
    font-size: 12px;
    background-color: #fafaf9;
    border: 1px solid #ccc;
    margin: 20px;
    padding: 20px;
    border-radius: 11px;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }

  .hidden {
    display: none;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &__credits.visible,
  .overlay.visible {
    opacity: 1;
  }

  &__credits-titre {
    font-family: "ClashDisplay", sans-serif;
    font-weight: 400;
  }

  &__credits-el {
    list-style: none;
    padding: 0;
    padding-bottom: 10px;
  }

  &__credits-btn {
    font-family: "ClashDisplay", sans-serif;
    margin-top: 20px;
    padding: 12px 15px;
    border: 2px dotted #333;
    border-radius: 11px;
    background-color: transparent;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s,
      transform 0.3s;
    cursor: pointer;
  }

  &__follow {
    flex: 1 1 100%;
  }

  &__socials {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px;
  }
}

@media (min-width: 600px) {
  .footer {
    &__centre {
      align-items: center;
    }

    &__follow {
      flex: 0 1 auto;
    }
  }
}

@media (min-width: 700px) {
  .footer {
    &__credits {
      font-size: 16px;
      bottom: 50px;
    }
  }
}

@media (min-width: 900px) {
  .footer {
    &__credits-content {
      display: flex;
      justify-content: space-around;

      & > div {
        flex-basis: 30%;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }

    &__credits-btn {
      margin-left: 15px;
    }
  }
}

@media (min-width: 1000px) {
  .footer {
    &__el {
      &:hover::before {
        width: 100%;
      }
    }
    &__credits-btn {
      &:hover {
        background-color: #415351;
        color: #fff;
        border-color: #fff;
        transform: scale(1.1);
      }
    }
  }
}

@media (min-width: 1600px) {
  .footer {
    &__credits {
      font-size: 20px;
    }
    &__credits-titre {
      font-size: 27px;
    }
    &__credits-btn {
      font-size: 16px;
    }
  }
}
