.a-propos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-bottom: 150px;

  &__titre {
    font-family: "ClashDisplay", sans-serif;
    font-size: 21px;
    color: #476662;
    text-transform: uppercase;
    word-spacing: 5px;
    margin-bottom: 60px;
    font-weight: 400;
    line-height: 140%;
  }

  &__texte {
    line-height: 250%;
    margin-bottom: 60px;
    font-size: 12px;
  }

  &__texte-bold {
    font-weight: 700;
  }

  &__img-span {
    width: 40px;
    height: 20px;
    vertical-align: text-bottom;
  }

  &__modif-1 {
    font-family: "Jura", sans-serif;
    background-color: #e4f7b3;
    color: #ce230c;
    padding: 5px;
  }

  &__modif-2 {
    background-color: #6b8bf6;
    color: #fcf520;
    padding: 0.2em 0.4em;
    border-radius: 5px;
  }

  &__modif-3 {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 0.5px;
      background-color: #f57a6a;
    }
  }

  &__modif-4 {
    font-family: "ClashDisplay", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #415351;
  }

  &__modif-5 {
    font-size: 18px;
    font-weight: bold;
    vertical-align: middle;
  }

  &__img {
    display: block;
    margin-left: auto;
    width: 120px;
    height: 147px;
  }

  &__btn {
    font-family: "ClashDisplay", sans-serif;
    font-size: 12px;
    display: block;
    margin-left: auto;
    margin-top: 20px;
    padding: 12px 15px;
    border: 2px dotted #333;
    border-radius: 11px;
    background-color: transparent;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s,
      transform 0.3s;
    cursor: pointer;
  }

  &__img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.projets {
  position: relative;
  margin-top: 120px;

  &__categories {
    list-style-type: none;
    margin-bottom: 20px;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  &__btn {
    font-family: "ClashDisplay", sans-serif;
    font-size: 28px;
    text-transform: uppercase;
    color: #415351;
    cursor: pointer;
    background: url("../assets/images/décoration/flèche.svg") left top no-repeat;
    background-size: 20px;
    margin-bottom: 20px;
    margin-right: 40px;
    padding-left: 30px;
    background-color: transparent;
    border: none;
    position: relative;
    transition: transform 0.3s;

    &.active {
      color: #333;
      font-weight: 500;
    }
  }

  &__type-btn,
  &__theme-btn {
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }

  &__boutons {
    font-family: "ClashDisplay", sans-serif;
    font-size: 12px;
    margin: 10px;
    padding: 15px 20px;
    white-space: nowrap;
    border: 2px dotted #333;
    border-radius: 11px;
    background-color: transparent;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s,
      transform 0.3s;
    cursor: pointer;

    &.active {
      background-color: #333;
      color: #fff;
      border-color: #fff;
    }
  }

  &__contenu {
    margin: 0;
    padding: 0;
  }

  &__titre {
    font-family: "ClashDisplay", sans-serif;
    font-size: 28px;
    font-weight: 400;
    color: #333;
  }

  &__sous-titre {
    font-weight: bold;
    color: #415351;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  &__texte {
    font-size: 12px;
  }

  &__grid {
    position: relative;
    margin-top: 80px;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 250px;
    gap: 20px;

    img {
      width: 100px;
    }

    a {
      text-decoration: none;
      color: #333;
      font-size: 12px;
      text-align: center;
    }
  }

  &__grid-item {
    position: relative;

    .hover-grid {
      display: none;
    }
  }

  &__grid-item1 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: end;
    position: relative;
    overflow: hidden;

    &.scaling {
      transform: scale(1.4);
      transition: transform 0.3s ease;
    }

    &.reduced {
      transform: scale(0.6);
      transition: transform 0.3s ease;
    }
  }

  &__grid-item2 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    position: relative;
    overflow: hidden;

    &.scaling {
      transform: scale(1.4);
      transition: transform 0.3s ease;
    }

    &.reduced {
      transform: scale(0.6);
      transition: transform 0.3s ease;
    }
  }

  &__grid-item3 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &.scaling {
      transform: scale(1.4);
      transition: transform 0.3s ease;
    }

    &.reduced {
      transform: scale(0.6);
      transition: transform 0.3s ease;
    }
  }
}

.notes {
  font-family: "SplineSansMono", sans-serif;
  width: 15%;
  padding: 10px;
  border-left: 2px dotted #ccc;
  box-sizing: border-box;
  margin-left: 150px;
  display: none;

  p {
    font-size: 12px;
    line-height: 150%;
    padding-left: 20px;
    padding-bottom: 20px;

    &:last-child {
      border-bottom: none;
    }
  }

  &__avatar {
    margin-top: 540px;
  }
  &__texte {
    margin-top: 30px;
  }
  &__images {
    margin-top: 200px;
  }
  &__clic {
    margin-top: 20px;
  }

  &__a-propos:hover,
  &__avatar:hover,
  &__projets:hover,
  &__premier:hover,
  &__deuxieme:hover,
  &__texte:hover,
  &__images:hover,
  &__clic:hover {
    cursor: pointer;
    background-color: rgb(244, 244, 244);
    border-radius: 11px;
  }
}

@media (min-width: 500px) {
  .projets {
    &__grid {
      img {
        width: 120px;
      }
    }
  }
}

@media (min-width: 600px) {
  .a-propos {
    margin-bottom: 200px;
    &__titre {
      font-size: 28px;
    }
    &__texte {
      font-size: 16px;
    }
    &__img-span {
      width: 50px;
      height: 30px;
    }
  }

  .projets {
    &__categories {
      flex-direction: row;
    }
    &__sous-titre {
      font-size: 20px;
    }
    &__texte {
      font-size: 16px;
      width: 80%;
    }
  }
}

@media (min-width: 700px) {
  .projets {
    &__grid {
      grid-template-columns: repeat(3, 1fr);

      img {
        width: 120px;
      }
    }

    &__btn-container {
      display: flex;
    }
  }
}

@media (min-width: 800px) {
  .a-propos {
    margin-top: 100px;
    margin-bottom: 200px;
    &__content {
      display: flex;
      margin-top: 150px;
    }

    &__titre {
      font-size: 36px;
    }

    &__texte {
      width: 85%;
    }

    &__img {
      margin-right: auto;
    }

    &__btn {
      margin-right: auto;
    }
  }

  .projets {
    &__container-flex {
      display: flex;
    }
    &__container {
      width: 100%;
    }
    &__btn {
      font-size: 36px;
    }
    &__titre {
      font-size: 36px;
    }
    &__grid {
      margin-top: 150px;
      a {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 1000px) {
  .a-propos {
    &__container {
      width: 100%;
    }
    &__img {
      width: 170px;
      height: 217px;
    }
    &__btn {
      &:hover {
        background-color: #415351;
        color: #fff;
        border-color: #fff;
        transform: scale(1.1);
      }
    }
    &__container {
      &.hovered {
        background-color: rgb(244, 244, 244);
        border-radius: 11px;
      }
    }
    &__img-container {
      &.hovered {
        background-color: rgb(244, 244, 244);
        border-radius: 11px;
      }
    }
  }

  .projets {
    &__container {
      &.hovered {
        background-color: rgb(244, 244, 244);
        border-radius: 11px;
      }
    }
    &__categories {
      &.hovered {
        background-color: rgb(244, 244, 244);
        border-radius: 11px;
      }
    }
    &__btn {
      &:hover {
        transform: scale(1.1);
        font-weight: 500;
      }
    }
    &__type-btn,
    &__theme-btn {
      &.hovered {
        background-color: rgb(244, 244, 244);
        border-radius: 11px;
      }
    }
    &__boutons {
      font-size: 16px;

      &:hover {
        background-color: #415351;
        color: #fff;
        border-color: #fff;
        transform: scale(1.1);
      }
    }
    &__contenu {
      &.hovered {
        background-color: rgb(244, 244, 244);
        border-radius: 11px;
      }
    }
    &__texte {
      width: 60%;
    }
    &__grid {
      img {
        width: 150px;
      }
      &.hovered {
        background-color: rgb(244, 244, 244);
        border-radius: 11px;
      }
    }
    &__grid-item {
      .hover-grid {
        position: absolute;
        top: -18px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;

        &__img {
          width: 210px;
          height: auto;
          object-fit: cover;
        }
      }

      &:hover .hover-grid {
        opacity: 1;
      }

      &.hovered {
        background-color: rgb(244, 244, 244);
        border-radius: 11px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .notes {
    display: block;
  }
}

@media (min-width: 1400px) {
  .a-propos {
    &__titre {
      font-size: 40px;
    }
    &__texte {
      font-size: 20px;
    }
  }
}

@media (min-width: 1600px) {
  .a-propos {
    &__titre {
      font-size: 47px;
    }
  }
  .projets {
    &__btn {
      font-size: 40px;
    }
  }
}

@media (min-width: 1800px) {
  .a-propos {
    &__img {
      width: 200px;
      height: 247px;
    }
  }
  .projets {
    &__btn {
      font-size: 47px;
    }
    &__boutons {
      font-size: 20px;
    }
    &__titre {
      font-size: 47px;
      margin-top: 100px;
    }

    &__sous-titre {
      font-size: 27px;
      margin-top: 60px;
      margin-bottom: 40px;
    }
    &__texte {
      font-size: 20px;
    }
    &__grid {
      margin-top: 200px;
    }
  }
  .notes {
    margin-left: 180px;
  }
}
