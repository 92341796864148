.header {
  position: relative;
  height: 100vh;
  transition: height 0.5s;
  margin-top: 5px;

  &__intro {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 27px;
    margin-right: 27px;
  }

  &__content {
    display: flex;
    align-items: center;

    &__texte {
      margin-right: 20px;
    }
  }

  &__icone {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 35px;
      transition: transform 0.3s;
    }
  }

  .navigation {
    &__list {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;
      align-items: flex-start;
    }

    &__el {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
      text-decoration: none;
      color: #333;
      font-weight: 700;
      transition: transform 0.3s;

      &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        height: 1px;
        background-color: #476662;
        transition: width 0.3s ease;
      }
    }
  }

  &__image {
    width: 100%;
    height: 95%;
    object-fit: cover;
    transform-origin: top;
    position: absolute;
    left: 0;
    z-index: 0;
  }

  &__texte-petit {
    font-family: "ClashDisplay", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    position: absolute;
    top: calc(50% - 50px);
    left: 50%;
    transform: translate(-50%, -100%);
    color: #d9d9d9;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 10;
  }

  &__texte-img {
    font-family: "ClashDisplay", sans-serif;
    font-size: 63px;
    font-weight: 200;
    color: #fafaf9;
    line-height: 120%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
}

@media (min-width: 0px) {
  .main {
    margin-left: 28px;
    margin-right: 28px;
  }
}

@media (min-width: 600px) {
  .header {
    &__texte-petit {
      font-size: 20px;
    }
    &__texte-img {
      font-size: 112px;
    }
    .navigation {
      &__list {
        flex-direction: row;
        align-items: center;
        gap: 40px;
      }
    }
  }
}

@media (min-width: 800px) {
  .main {
    margin-left: 36px;
    margin-right: 36px;
  }
}

@media (min-width: 1000px) {
  .header {
    &__texte-petit {
      font-size: 27px;
    }
    &__texte-img {
      font-size: 199px;
    }
    img {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .navigation {
    &__el {
      &:hover::before {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1400px) {
  .main {
    margin-left: 63px;
  }
}

@media (min-width: 1600px) {
  .header {
    &__texte-petit {
      font-size: 36px;
    }
    &__texte-img {
      font-size: 266px;
    }
  }
}

@media (min-width: 1800px) {
  .main {
    margin-left: 112px;
  }
}
