.projet-body {
  margin: 8px;
  padding: 0;
}

.travaux {
  margin-top: 100px;

  &__titre {
    font-family: "ClashDisplay", sans-serif;
    font-weight: 400;
    font-size: 28px;
    text-transform: uppercase;
    color: #333;
    line-height: 120%;
  }

  &__date {
    font-size: 16px;
    margin-left: 10px;
    color: #415351;
  }

  &__skills {
    margin: 0;
    padding: 0;
    display: flex;
  }

  &__skills-texte {
    font-size: 10px;
    list-style: none;
    font-family: "ClashDisplay", sans-serif;
    margin-bottom: 40px;
    margin-right: 5px;
    padding: 3px 5px;
    border: 1px solid #415351;
    border-radius: 11px;
    background-color: transparent;
    color: #415351;
    text-decoration: none;
  }

  &__texte--hidden {
    display: none;
  }

  &__texte--visible {
    display: block;
  }

  &__texte-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.2s;
    padding: 10px;
  }

  .btn-img {
    width: 150%;
  }

  .hidden {
    display: none;
  }
}

.slider {
  width: 80%;
  max-width: 1200px;
  margin: 100px auto 0;
  position: relative;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    height: 320px;
  }

  &__el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    transform: translateX(-50px);

    &--show {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      transform: translateX(0);
    }
  }

  &__img {
    max-width: 200px;
    margin: 0;
  }

  &__img--grande {
    max-width: 100px;
  }

  &__content {
    font-size: 12px;
    text-align: center;
    padding: 10px;
    width: 100%;
    color: #333;
  }

  &__audio {
    margin-top: 10px;
  }

  &__controls {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    list-style: none;

    .slider__btn {
      button {
        border: none;
        background-color: transparent;
        padding: 10px;
        cursor: pointer;
        transition: transform 0.2s;
      }
    }
  }

  &__indicateurs-wrapper {
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
  }

  &__indicateurs {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: baseline;
  }

  &__indicateurs-el {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #333;
    background-color: transparent;
    margin-right: 5px;
    cursor: pointer;

    &:last-child {
      margin-right: 0; // Supprime le margin-right sur le dernier élément
    }
  }

  &__indicateurs-el--active {
    background-color: #333;
  }

  &__acces {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    padding: 0;
  }

  &__acces-list {
    list-style: none;
  }

  &__acces-el {
    font-family: "ClashDisplay", sans-serif;
    font-size: 12px;
    padding: 15px 20px;
    white-space: nowrap;
    border: 2px dotted #333;
    border-radius: 11px;
    background-color: transparent;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s,
      transform 0.3s;
    cursor: pointer;
  }

  &__images {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
    overflow-x: hidden;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__images:before {
    content: "";
    flex-shrink: 0;
    width: 10px;
  }

  &__images-el {
    margin-left: 10px;
    flex-shrink: 0;
    transition: transform 0.3s;
  }

  &__images-img {
    width: 100px;
    display: block;
  }

  &__images-el--active {
    transform: scale(1.2);
  }
}

.footer {
  margin-top: 240px;
}

@media (min-width: 300px) {
  .travaux {
    &__titre {
      font-size: 38px;
    }

    &__date {
      font-size: 21px;
    }

    &__skills-texte {
      font-size: 12px;
      padding: 5px 10px;
    }
  }

  .slider {
    &__list {
      height: 300px;
    }

    &__img {
      max-width: 250px;
    }

    &__img--grande {
      max-width: 150px;
    }
  }
}

@media (min-width: 500px) {
  .travaux {
    &__skills-texte {
      margin-right: 10px;
    }
  }

  .slider {
    &__list {
      height: 420px;
    }

    &__img {
      max-width: 350px;
    }

    &__img--grande {
      max-width: 250px;
    }
  }
}

@media (min-width: 600px) {
  .travaux__top {
    margin-left: 36px;
    margin-right: 36px;
  }
}

@media (min-width: 800px) {
  .travaux {
    &__titre {
      font-size: 47px;
    }

    &__top {
      margin-left: 84px;
      margin-right: 84px;
    }
  }
}

@media (min-width: 1000px) {
  .travaux {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 20px;

    &__titre {
      font-size: 63px;
    }

    &__date {
      font-size: 27px;
    }

    &__skills-texte {
      font-size: 16px;
      padding: 10px 12px;
    }

    &__top {
      margin-right: 266px;
      margin-bottom: 80px;
    }

    &__mid {
      width: 50%;
      max-width: 100%;
      margin-right: 60px;
    }

    &__bottom {
      width: 50%;
      max-width: 500%;
    }

    &__texte-btn {
      &:hover {
        transform: scale(1.3);
      }
    }

    .slider {
      display: flex;
      width: 90%;
      justify-content: space-between;

      &__content {
        font-size: 16px;
      }

      &__acces-el {
        font-size: 16px;

        &:hover {
          background-color: #415351;
          color: #fff;
          border-color: #fff;
          transform: scale(1.1);
        }
      }

      .slider__btn {
        button {
          &:hover {
            transform: scale(1.3);
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .slider {
    width: 100%;

    &__list {
      height: 500px;
    }

    &__img {
      max-width: 450px;
    }

    &__img--grande {
      max-width: 300px;
    }
  }
}

@media (min-width: 1400px) {
  .travaux {
    &__top {
      margin-right: 472px;
    }
  }

  .slider {
    width: 100%;

    &__list {
      height: 500px;
    }

    &__img {
      max-width: 550px;
    }

    &__img--grande {
      max-width: 400px;
    }

    &__audio {
      height: 100px;
    }
  }
}

@media (min-width: 1600px) {
  .travaux {
    &__travaux__texte {
      margin-left: 150px;
      margin-right: 629px;
    }
  }

  .slider {
    gap: 100px;
    &__list {
      height: 650px;
    }

    &__img {
      max-width: 600px;
    }

    &__img--grande {
      max-width: 420px;
    }
  }
}

@media (min-width: 1800px) {
  .slider {
    gap: 200px;
    &__img {
      max-width: 650px;
    }

    &__img--grande {
      max-width: 450px;
    }
  }
}

@media (min-width: 1900px) {
  .travaux {
    &__titre {
      font-size: 70px;
    }

    &__date {
      font-size: 36px;
    }

    &__top {
      margin-right: 629px;
      margin-bottom: 100px;
    }
  }
  .slider {
    &__list {
      height: 700px;
    }

    &__img {
      max-width: 700px;
    }

    &__img--grande {
      max-width: 500px;
    }
  }
}
