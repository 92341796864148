@import "utils/variables";
@import "components/header";
@import "components/footer";
@import "components/sections";
@import "components/font";
@import "components/projets";

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fafaf9;
  font-family: "Satoshi", sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
